(function() {
    'use strict';

    angular.module('ionicNotification', [
        'ionic',
        'ionic-toast',
        'ionicData',
        'ionicForceRefresh',
        'ionicLogin',
        'ionicInfiniteScroll',
        'ionicOffline',
        'ionicRequest',
        'uabDataRefresh',
        'uabDefaultVariable',
        'uabDefaultImage',
        'uabEnvironment',
        'uabMoment',
        'uabPromiseManagement',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicNotification').service('NotificationService', NotificationService);

    NotificationService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'ListManagementService',
        'PromiseManagementService',
        'RedirectService',
        'RequestManagementService'
    ];

    function NotificationService(
        DefaultVariableService,
        EnvironmentService,
        ListManagementService,
        PromiseManagementService,
        RedirectService,
        RequestManagementService
    ) {
        var NotificationService = this;

        NotificationService.buildOptions = buildOptions;
        function buildOptions(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'notifications');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'Images'
                ]
            );

            options = RequestManagementService.setOrder(
                options,
                [
                    'has_read',
                    'created'
                ]
            );

            return options;
        }

        NotificationService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        NotificationService.createNotification = createNotification;
        function createNotification(newNotification) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notifications');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newNotification);

            return ListManager.create(options);
        }

        NotificationService.editNotification = editNotification;
        function editNotification(notification) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notifications');
            options = RequestManagementService.setAction(options, 'edit');

            options = RequestManagementService.setData(options, notification);

            return ListManager.edit(options);
        }

        NotificationService.getNotifications = getNotifications;
        function getNotifications(options) {
            return NotificationService.loadNotifications(options);
        }

        NotificationService.getMore = getMore;
        function getMore(options) {
            options = NotificationService.buildOptions(options);

            return ListManager.getMore(options);
        }

        NotificationService.getPage = getPage;
        function getPage(options) {
            options = NotificationService.buildOptions(options);

            return ListManager.getPage(options);
        }

        NotificationService.markAsRead = markAsRead;
        function markAsRead(notification) {
            notification.has_read = true;

            return NotificationService.editNotification(notification).then(
                function(response) {
                    if (!response) {
                        notification.has_read = false;
                    }

                    return response;
                }
            );
        }

        NotificationService.navigateTo = navigateTo;
        function navigateTo(notification) {
            if (DefaultVariableService.isObject(notification)) {
                var notifications = EnvironmentService.get('notifications');

                var sref = notification.type;
                sref = DefaultVariableService.get(
                    notifications[sref],
                    sref
                );

                var options = {};
                if (DefaultVariableService.isDefined(notification.reference_name) && notification.reference_name !== 0) {
                    if (DefaultVariableService.isDefined(notification.reference_value) && notification.reference_value !== 0) {
                        options[notification.reference_name] = notification.reference_value;
                    }
                }

                RedirectService.goTo(sref, options);
            }

            return PromiseManagementService.generateSuccess(true);
        }

        NotificationService.selectNotification = selectNotification;
        function selectNotification(notification) {
            var promise = false;
            if (notification.has_read) {
                promise = PromiseManagementService.generateSuccess(true);
            } else {
                promise = NotificationService.markAsRead(notification);
            }

            return promise.then(
                function (response) {
                    if (response) {
                        NotificationService.navigateTo(notification);
                    }

                    return response;
                }
            );
        }

        NotificationService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            NotificationService.getMore
        );

        NotificationService.reset();

        return NotificationService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').service(
        'UnreadNotificationsService',
        UnreadNotificationsService
    );

    UnreadNotificationsService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        '$interval',
        'LoginService',
        'MomentService',
        'NotificationService',
        'OfflineService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function UnreadNotificationsService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        $interval,
        LoginService,
        MomentService,
        NotificationService,
        OfflineService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var UnreadNotificationsService = this;

        UnreadNotificationsService.getNewestNotifications = getNewestNotifications;
        function getNewestNotifications() {
            return UnreadNotificationsService.newestNotifications;
        }

        UnreadNotificationsService.getUnreadCount = getUnreadCount;
        function getUnreadCount() {
            return UnreadNotificationsService.unreadCount;
        }

        UnreadNotificationsService.loadNewestNotifications = loadNewestNotifications;
        function loadNewestNotifications() {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notifications');
            options = RequestManagementService.setAction(options, 'newNotifications', UnreadNotificationsService.seconds);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        UnreadNotificationsService.newestNotifications = DefaultVariableService.getArray(
                            data.newest_notifications
                        );
                    }

                    return data;
                }
            );
        }

        UnreadNotificationsService.loadUnreadCount = loadUnreadCount;
        function loadUnreadCount(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'notifications');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'has_read=0'
                ]
            );

            options = RequestManagementService.setPage(options, 0);

            if (OfflineService.isOnline()) {
                return DataManagementService.request(options).then(
                    function(data) {
                        if (data) {
                            UnreadNotificationsService.unreadCount = DefaultVariableService.getInteger(
                                data.count,
                                0
                            );
                        }

                        return UnreadNotificationsService.unreadCount;
                    }
                );
            } else {
                options = RequestManagementService.setLimit(options, 1000);

                return DataManagementService.request(options).then(
                    function(data) {
                        if (data) {
                            var notifications = DefaultVariableService.getArray(
                                data.notifications
                            );

                            UnreadNotificationsService.unreadCount = notifications.length;
                        }

                        return UnreadNotificationsService.unreadCount;
                    }
                );
            }
        }

        UnreadNotificationsService.reset = reset;
        function reset() {
            UnreadNotificationsService.newestNotifications = [];

            UnreadNotificationsService.newestTimeout = false;

            UnreadNotificationsService.seconds = EnvironmentService.get(
                'unreadNotificationSeconds',
                60
            );

            UnreadNotificationsService.unreadCount = 0;

            UnreadNotificationsService.unreadTimeout = false;

            LoginService.register(
                {
                    init:     UnreadNotificationsService.resetIntervals,
                    onLogin:  UnreadNotificationsService.resetIntervals,
                    onLogout: UnreadNotificationsService.clearIntervals
                }
            );
        }

        UnreadNotificationsService.clearIntervals = clearIntervals;
        function clearIntervals() {
            if (UnreadNotificationsService.newestTimeout) {
                UnreadNotificationsService.newestTimeout.cancel();
            }

            if (UnreadNotificationsService.unreadTimeout) {
                UnreadNotificationsService.unreadTimeout.cancel();
            }
        }

        UnreadNotificationsService.resetIntervals = resetIntervals;
        function resetIntervals() {
            UnreadNotificationsService.clearIntervals();

            UnreadNotificationsService.newestTimeout = $interval(
                function() {
                    UnreadNotificationsService.loadNewestNotifications();
                },
                UnreadNotificationsService.seconds * 1000
            );

            UnreadNotificationsService.unreadTimeout = $interval(
                function() {
                    UnreadNotificationsService.loadUnreadCount();
                },
                UnreadNotificationsService.seconds * 1000
            );
        }

        UnreadNotificationsService.init = init;
        function init() {
            UnreadNotificationsService.loadNewestNotifications();
            UnreadNotificationsService.loadUnreadCount();
            UnreadNotificationsService.reset();
        }

        UnreadNotificationsService.init();

        return UnreadNotificationsService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').controller(
        'NotificationListController',
        NotificationListController
    );

    NotificationListController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'NotificationService',
        'LoginService',
        'MomentService',
        'RequestManagementService',
        '$scope'
    ];

    function NotificationListController(
        DefaultImageService,
        DefaultVariableService,
        NotificationService,
        LoginService,
        MomentService,
        RequestManagementService,
        $scope
    ) {
        var NotificationListController = this;

        NotificationListController.options = RequestManagementService.getRequest();

        $scope.$watch(
            'collapsible',
            function(collapsible) {
                NotificationListController.collapsible = DefaultVariableService.get(
                    collapsible,
                    false
                );
            }.bind(NotificationListController)
        );

        $scope.$watch(
            'onlyUnread',
            function(onlyUnread) {
                NotificationListController.onlyUnread = DefaultVariableService.get(
                    onlyUnread,
                    false
                );

                NotificationListController.loadNotifications();
            }.bind(NotificationListController)
        );

        $scope.$watch(
            'showCount',
            function(showCount) {
                NotificationListController.showCount = DefaultVariableService.get(
                    showCount,
                    false
                );
            }.bind(NotificationListController)
        );

        NotificationListController.clear = clear;
        function clear() {
            NotificationService.reset();
            NotificationListController.reset();
        }

        NotificationListController.forceRefresh = forceRefresh;
        function forceRefresh() {
            NotificationListController.clear();
            NotificationListController.init();

            NotificationListController.notifications = [];
            NotificationListController.loadNotifications();
        }

        NotificationListController.getNotificationImage = getNotificationImage;
        function getNotificationImage(notification) {
            return DefaultImageService.getNotificationImage(notification);
        }

        NotificationListController.loadNotifications = loadNotifications;
        function loadNotifications() {
            NotificationListController.isLoadingNotifications = true;

            if (NotificationListController.onlyUnread) {
                NotificationListController.options = RequestManagementService.setParams(
                    options,
                    [
                        'has_read=0'
                    ]
                );
            }

            return NotificationService.getMore(
                NotificationListController.options
            ).then(
                function(data) {
                    if (data) {
                        NotificationListController.notifications = DefaultVariableService.getArray(
                            data.notifications
                        );

                        if (NotificationListController.notifications.length === 0) {
                            NotificationListController.isCollapsed = true;
                        }
                    }

                    NotificationListController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return data;
                }
            ).finally(
                function() {
                    NotificationListController.isLoadingNotifications = false;
                }
            );
        }

        NotificationListController.selectNotification = selectNotification;
        function selectNotification(notification) {
            return NotificationService.selectNotification(notification).then(
                function(updatedNotification) {
                    if (updatedNotification) {
                        notification = updatedNotification;
                    }

                    return notification;
                }
            );
        }

        NotificationListController.toggleCollapse = toggleCollapse;
        function toggleCollapse() {
            NotificationListController.isCollapsed = DefaultVariableService.toggle(
                NotificationListController.isCollapsed,
                false
            );
        }

        NotificationListController.reset = reset;
        function reset() {
            NotificationListController.canLoadMore = true;

            NotificationListController.collapsible = false;

            NotificationListController.isCollapsed = false;

            NotificationListController.isLoadingNotifications = false;

            NotificationListController.moment = MomentService;

            NotificationListController.notifications = [];

            NotificationListController.showCount = false;

            NotificationListController.title = DefaultVariableService.get(
                NotificationListController.title,
                false
            );
        }

        NotificationListController.init = init;
        function init() {
            NotificationListController.reset();
        }

        LoginService.register(NotificationListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').directive(
        'ionicNotificationList',
        ionicNotificationList
    );

    function ionicNotificationList() {
        return {
            bindToController: {
                title: '@'
            },
            controller:   'NotificationListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                collapsible: '=',
                onlyUnread:  '=',
                showCount:   '='
            },
            template:'<ion-list class="list"><ionic-force-refresh data-ng-if="!ctrl.collapsible" refresh-function="ctrl.forceRefresh"></ionic-force-refresh><div data-ng-if="ctrl.title || ctrl.collapsible" class="row item bar bar-stable buttons" data-ng-click="ctrl.toggleCollapse()"><h1 class="title">{{ ctrl.title }}&nbsp; <span data-ng-if="ctrl.showCount && !ctrl.isLoadingNotifications">({{ ctrl.notifications.length }})</span></h1><button data-ng-if="ctrl.collapsible" class="button button-icon" data-ng-disabled="ctrl.notifications.length === 0">{{ ctrl.isCollapsed ? \'SHOW\' : \'HIDE\' }} <i class="icon" data-ng-class="ctrl.isCollapsed ? \'ion-arrow-expand\' : \'ion-arrow-shrink\'"></i></button></div><div class="animated" data-ng-class="ctrl.isCollapsed ? \'fadeOutUp\' : \'fadeInDown\'" data-ng-if="!ctrl.collapsible || !ctrl.isCollapsed"><a class="item item-avatar" data-ng-class="{ \'item-icon-right\': !notification.has_read }" data-ng-repeat="notification in ctrl.notifications track by $index" data-ng-click="ctrl.selectNotification(notification)"><img data-ng-if="ctrl.getNotificationImage(notification)" data-ng-src="{{ ctrl.getNotificationImage(notification) }}"> <i class="icon ion-alert assertive" data-ng-if="!notification.has_read"></i><h2>{{ notification.message }}</h2><p>{{ ctrl.moment(notification.created).fromNow() }}</p></a><div class="item bar bar-stable" data-ng-if="!ctrl.canLoadMore"><h1 class="title">NO {{ ctrl.notifications.length === 0 ? \'\' : \'MORE\' }} NOTIFICATIONS</h1></div></div><ionic-infinite-scroll data-ng-if="ctrl.canLoadMore && !ctrl.isLoadingNotifications" can-load-more="ctrl.canLoadMore" immediate-check="false" is-loading="ctrl.isLoadingNotifications" load-more="ctrl.loadNotifications"></ionic-infinite-scroll></ion-list><span data-ng-if="ctrl.isLoadingNotifications"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').controller(
        'NotificationToastController',
        NotificationToastController
    );

    NotificationToastController.$inject = [
        'DefaultVariableService',
        'ionicToast',
        'LoginService',
        'UnreadNotificationsService',
        'RedirectService',
        '$scope'
    ];

    function NotificationToastController(
        DefaultVariableService,
        ionicToast,
        LoginService,
        UnreadNotificationsService,
        RedirectService,
        $scope
    ) {
        var NotificationToastController = this;

        NotificationToastController.notifications = [];

        $scope.$watch(
            UnreadNotificationsService.getNewestNotifications,
            function(notifications) {
                NotificationToastController.setNotifications(notifications);
            }
        );

        NotificationToastController.loadNewestNotifications = loadNewestNotifications;
        function loadNewestNotifications() {
            NotificationToastController.isLoadingNotifications = true;

            return UnreadNotificationsService.loadNewestNotifications().finally(
                function() {
                    NotificationToastController.isLoadingNotifications = false;
                }
            );
        }

        NotificationToastController.setNotifications = setNotifications;
        function setNotifications(notifications) {
            NotificationToastController.notifications = DefaultVariableService.getArray(
                notifications
            );

            var notificationsLength = NotificationToastController.notifications.length;
            for (var i = 0; i < notificationsLength; i++) {
                ionicToast.show(
                    NotificationToastController.notifications[0].message,
                    'top',
                    false,
                    3000
                );
            }
        }

        NotificationToastController.reset = reset;
        function reset() {
            NotificationToastController.isLoadingNotifications = false;

            NotificationToastController.sref = DefaultVariableService.get(
                NotificationToastController.sref,
                'app.unreadNotifications'
            );
        }

        NotificationToastController.init = init;
        function init() {
            NotificationToastController.reset();

            NotificationToastController.loadNewestNotifications();
        }

        LoginService.register(NotificationToastController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').directive(
        'ionicNotificationToast',
        ionicNotificationToast
    );

    function ionicNotificationToast() {
        return {
            controller:   'NotificationToastController'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').controller(
        'UnreadNotificationsController',
        UnreadNotificationsController
    );

    UnreadNotificationsController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'UnreadNotificationsService',
        'RedirectService',
        '$scope'
    ];

    function UnreadNotificationsController(
        DefaultVariableService,
        LoginService,
        UnreadNotificationsService,
        RedirectService,
        $scope
    ) {
        var UnreadNotificationsController = this;

        $scope.$watch(
            UnreadNotificationsService.getUnreadCount,
            function(unreadCount) {
                UnreadNotificationsController.unreadCount = DefaultVariableService.getInteger(
                    unreadCount,
                    0
                );
            }
        );

        UnreadNotificationsController.clear = clear;
        function clear() {
            UnreadNotificationsService.reset();
            UnreadNotificationsController.reset();
        }

        UnreadNotificationsController.loadUnreadCount = loadUnreadCount;
        function loadUnreadCount(options) {
            return UnreadNotificationsService.getUnreadCount(options);
        }

        UnreadNotificationsController.reset = reset;
        function reset() {
                UnreadNotificationsController.sref = DefaultVariableService.get(
                UnreadNotificationsController.sref,
                'app.unreadNotifications'
            );

            UnreadNotificationsController.unreadCount = 0;
        }

        UnreadNotificationsController.init = init;
        function init() {
            UnreadNotificationsController.reset();

            UnreadNotificationsController.loadUnreadCount();
        }

        LoginService.register(UnreadNotificationsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotification').directive(
        'ionicUnreadNotifications',
        ionicUnreadNotifications
    );

    function ionicUnreadNotifications() {
        return {
            bindToController: {
                sref: '@'
            },
            controller:   'UnreadNotificationsController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                unreadCount: '='
            },
            template:'<a class="button icon ion-android-notifications primary-color animated bounceIn" data-ui-sref="{{ ctrl.sref }}"><span data-ng-if="ctrl.unreadCount !== 0" class="icon-badge">{{ ctrl.unreadCount }}</span></a>'
        };
    }
})();